:root{--h:250px;--v:10px}
[class*='col-']{padding-right:0;padding-left:0;}
.row{margin-right: 0px !important;margin-left: 0px !important;}

@font-face{font-family: Poppins-Regular;src: url(../fonts/poppins/Poppins-Regular.otf);}
@font-face{font-family: Poppins-Light;src: url(../fonts/poppins/Poppins-Light.otf);}
@font-face{font-family: Poppins-Medium;src: url(../fonts/poppins/Poppins-Medium.otf);}
@font-face{font-family: Poppins-SemiBold;src: url(../fonts/poppins/Poppins-SemiBold.otf);}
@font-face{font-family: Poppins-Bold;src: url(../fonts/poppins/Poppins-Bold.otf);}
@font-face{font-family: Poppins-Black;src: url(../fonts/poppins/Poppins-Black.otf);}
@font-face{font-family: Poppins-ExtraBold;src: url(../fonts/poppins/Poppins-ExtraBold.otf);}

h1,h2,h3,h4,h5,h6,p{color:#0C0C0C;}
body{font-family:Poppins-Regular;font-size: 24px;line-height: 36px;background:#fff;color:#0C0C0C;overflow-x: hidden;}
h1,h2,h3,h4,h5,h6,b,strong,.grid-box-title h4 b{font-family: Poppins-SemiBold;}
.grid-box-title h4 span,.scroll-box-content h5{font-family: Poppins-Regular;}
.digital-section .section-title h2{font-family: Poppins-Bold;}
h1 b,h2 b,h3 b,h4 b,h5 b,h6 b{font-family: Poppins-Bold;}
.post-box-tag ul li a,.post-box-desc p,.faq-section .card-body p,.digital-form-inner .form-control,.price-section .section-desc p,.price-info-top p,.footer-menu ul li a,.footer-form .form-control
{font-family: Poppins-Light;}
b,strong{}
a:hover{cursor:pointer;}
a{text-decoration: none;}
.monthly-selected{font-size:16px;padding-top:0px;}
.monthly-unselected{font-size:15px;padding-left:20px;padding-top:16px;color:#FFF}
.yearly-unselected{font-size:15px;padding-top:16px;color:#FFF}
#text{/* transition: 0.1s ease; */}
.Video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }
  
  /** hide the snippet's console **/
  .as-console-row {
    display: none !important;
  }
  #tv{width:100%;height:586px;border-radius:25px}
  .ui.inline.dropdown>.text{font-size: 20px;}
/*Container CSS Starts*/
@media (min-width: 1320px)
{
    .container-large{max-width: 1300px;}
}
@media (min-width: 1200px)
{
    .container-big{max-width: 1200px;}
}
@media (min-width: 990px)
{
    .container-small{max-width: 850px;}
}
/*Container CSS Ends*/

/*Buttons CSS Starts*/
.btn{font-size: 24px;padding: 14px 46px;border: 0px;border-radius: 12px;transition: all 0.3s ease-in;}
.btn-main{font-size: 20px;padding: 15px 115px;}
.btn-red{background-color: #C9002C;color: #ffffff;}
.btn-pink{background-color: rgba(201, 0, 44, 0.1);color: #C9002C;padding: 6px 32px !important;}

.btn-link{font-size: 24px;padding-right: 25px;background-repeat: no-repeat;background-position: 100% 9px;background-size: 16px;text-decoration: none;}
.btn-link-white{color: #ffffff;background-image: url("../images/akar-icons-arrow-up-right2.svg");}
.btn-link-red{color: #EB0029;background-image: url("../images/akar-icons-arrow-up-right3.svg");}
.btn-gradient{color: #ffffff;background: linear-gradient(90deg, #EB0029 -12%, #2C67FF 117.14%);}
/*Buttons CSS Ends*/


/*Gradient Text CSS Starts*/
.gradient-text{background: linear-gradient(90.07deg, #C9002C -33.9%, #FFC56F 109.28%);/*background: linear-gradient(to right, #0F3D95 0%, #009DE1 100%);*/-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.gradient-text-coach{background: linear-gradient(90.07deg, #EB0029 -8.9%, #2C67FF 97.28%);/*background: linear-gradient(to right, #0F3D95 0%, #009DE1 100%);*/-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
/*Gradient Text CSS Ends*/

/*Title Section CSS Starts*/
.title-section{}
.section-title{}
.section-title h4{font-size: 19px;line-height: 45px;color: #C9002C;}
.section-title h2{font-size: 69px;line-height: 60px;color: #0C0C0C;}
.section-title p{font-size: 24px;line-height: 36px;color: #111111;}
.section-title-white h2{color: #ffffff;}
.section-desc p span.bar{color: #C9002C;}
.section-title-large h2{font-size: 100px;line-height: 124px;}
/*Title Section CSS Ends*/


/*Header CSS Starts*/
header{padding-top: 32px;padding-bottom: 40px;}
.navbar{padding: 0px;}
.navbar-expand-lg .navbar-collapse{justify-content: flex-end;}
header ul.navbar-nav{align-items: center;padding-left: 260px;}
header ul.navbar-nav li{margin: 0px 16px;}
header ul.navbar-nav li a{font-size: 20px;line-height: 30px;color: #000 !important;padding: 0px 10px;transition: all 0.3s ease-in;position: relative;}
header ul.navbar-nav li:first-child{margin-left: 0px;}
header ul.navbar-nav li:last-child{margin-right: 0px;}
header ul.navbar-nav li a:before{content: '';width: 100%;height: 3px;position: absolute;bottom: -5px;left: 0;background-color: transparent;transition: all 0.4s ease-in;}
/* header ul.navbar-nav li.active a:before,header ul.navbar-nav li a:hover::before{background-color: #C9002C;}
header ul.navbar-nav li.active a:before,header ul.navbar-nav li a:hover::before{background-color: #C9002C;} */
header ul.navbar-nav li a.active:before,header ul.navbar-nav li a:hover::before{background-color: #C9002C;}
header ul.navbar-nav li a.active:before,header ul.navbar-nav li a:hover::before{background-color: #C9002C;}
header ul.navbar-nav li.btn{margin-left: 00px; padding: 6px 46px;}
header ul.navbar-nav li.btn a{color: #ffffff !important;padding-left: 0px;padding-right: 0px;}
header ul.navbar-nav li.btn a:hover::before{display: none;}
#btn-color {color:#C9002C !important}

/*Header CSS Ends*/

/*Inner page Banner CSS Starts*/
.inner-page-banner{}
.inner-page-banner img{width: 100%;height: 486px;object-fit: cover;}
/*Inner page Banner CSS Ends*/


/*Inner page CSS Starts*/
.inner-page-title{padding: 50px 0px;}
.inner-page-title h1{}
.inner-page-content{padding-bottom: 200px;}
.inner-page-content h3{font-size: 32px;line-height: 48px;}
.inner-page-content p{}
/*Inner page CSS Ends*/

/*Popup Section CSS Starts*/
/* .popup-section{position: fixed;top: 0;left: 0;width: 100%;height: 100vh;padding-top: 10%;background-color: rgba(0,0,0,0.7);z-index: 99;} */
/* .popup-inner{max-width:938px;width:100%;display:block;margin:auto;padding:40px 60px 112px 60px;background-image:url(../images/Allianz_xuan_lan.png);background-size:cover;background-position:center center;position:relative;margin:0 auto;position:absolute;top:50%;-ms-transform:translateY(-50%);transform:translateY(-50%);left:0;right:0} */
.popup-section{margin-top: 60px;}
.popup-inner{width:100%;display:block;margin:auto;padding:40px 60px 112px 60px;/*background-image:url(../images/Allianz_xuan_lan.png);*/background-size:cover;background-position:center center;position:relative;margin:0 auto;}
.popup-inner:before{content: '';width: 100%;height: 100%;position: absolute;top: 0;left: 0;background-color: rgba(0,0,0,0.1);}
.popup-close{position: absolute;top: 50px;right: 60px;}
.popup-close a{}
.popup-close img{}
.popup-content{max-width: 371px;margin-top: 26px;position: relative;}
.popup-title{}
.popup-title h2,.popup-desc p{color: #ffffff;}
.popup-title h2{font-size: 40px;line-height: 56px;font-weight: 700;}
.popup-desc{margin-top: 26px;}
.popup-desc p{font-size: 16px;line-height: 23px;}
.popup-btn{margin-top: 37px;}
.popup-btn .btn{}
.popup-section-new{}
.popup-section-new .popup-content{max-width: 520px;}
.popup-section-new .popup-desc{margin-top: 0px;}
.popup-section-new .popup-title h2{font-size: 60px;line-height: 85px;}
.popup-section-new .popup-desc p{font-size: 24px;line-height: 34px;}
/*Popup Section CSS Ends*/

/*Cookies Popup Section CSS Starts*/
.cookies-popup-section{width: 100%;background-color: #013F87;position: fixed;bottom: 0;left: 0;z-index: 99;}
.cookies-inner{padding: 18px 0px;}
.cookies-popup-left{}
.cookies-popup-right{}
.cookies-content{}
.cookies-content p{font-size: 13px;line-height: 20px;color: #ffffff;margin: 0px;}
.cookies-popup-btn-outer{text-align: right;}
.cookies-popup-btn-outer a{font-size: 12px;font-weight: 700;padding: 8px 10px;border-radius: 2px;}
.cookies-link{color: #ffffff;text-decoration: underline;margin-right: 10px;}
.cookies-btn{background-color: #ffffff;color: #013F87;}
/*Cookies Popup Section CSS Ends*/

.bs-text-section{padding-bottom: 100px;}
.content-text{margin-top: 80px;}
.content-text h3{font-size: 32px;line-height: 46px;color: #013F87;margin-bottom: 24px;}
.content-text p{font-size: 16px;line-height: 22px;}


/*Dots Section CSS Starts*/
.dots-section{}
.dots-img{text-align: center;}
.dots-img img{}
/*Dots Section CSS Ends*/

/*Banner Section CSS Starts*/
.banner-section{padding-top: 25px;padding-bottom: 25px;}
.banner-inner{}
.banner-left{padding-right: 100px;}
.banner-right{}
.banner-left-inner{}
.banner-content{}
.banner-title{}
.banner-title h1{font-size: 100px;line-height: 124px;}
.banner-desc{margin-top: 20px;}
.banner-desc p{}
.banner-logos{margin-top: 60px;}
.banner-logos ul{display: flex;flex-wrap: wrap;flex-direction: row;margin: 0px;padding: 0px;}
.banner-logos ul li{list-style: none;margin-right: 15px;}
.banner-logos ul li a{}
.banner-logos ul li a img{}
.banner-right-inner{}
.banner-img{}
.banner-img img{max-width: 870px;margin-left: -380px;margin-top: -85px;z-index: -1;}
/*Banner Section CSS Ends*/

/*Content Img Section CSS Starts*/
.content-img-section{padding-top: 30px;padding-bottom: 100px;}
.content-img-inner{position: relative;}
.content-img-left{}
.content-img-right{}
.content-img-pic{}
.content-img-pic img{width: 100%;}
.content-img-data{}
.content-img-data .section-desc{margin-top: 36px;}
.content-img-data .section-desc p{margin-bottom: 30px;}
.content-link-outer{margin-top: 50px;}
.content-link-outer .btn-link{}
.content-img-section{background-repeat: no-repeat;}
.content-img-section-1{background-image: url("../images/MacBookPro17.png");background-size: 600px;background-position: 0px 100px;}
.content-img-section-2{background-image: url("../images/iPadPro.png");background-size: 586px;background-position: 100% 100%;}
.content-img-section-3{background-image: url("../images/iPhone-14.png");background-size: 500px;background-position: 0px 70px;}
.content-img-section-01{background-image: url("../images/MacBookPro2.png");background-size: 570px;background-position: 0px 70px;padding-bottom: 170px;}
.content-img-section-02{background-image: url("../images/athlete-right.png");background-size: 600px;background-position: 82% 0;padding-top: 130px;padding-bottom: 130px;}

.content-img-section-001{background-image: url("../images/iPad-Mini.png");background-size: 720px;background-position: 16% 50%;padding-top: 200px;padding-bottom: 150px;}
.content-img-section-1 .content-img-pic img,.content-img-section-3 .content-img-pic img{max-width: 650px;}
.content-img-section-1 .content-img-pic img,.content-img-section-3 .content-img-pic img{/*margin-left: -56px;*/}
.content-img-section-2 .content-img-pic img{max-width: 650px;}
.content-img-section-2 .content-img-pic img{/*margin-left: 56px;*/margin-top: -52px;}
.content-img-section-2 .content-img-data {margin-right: -102px;}
.content-img-section-1 .content-img-right,.content-img-section-3 .content-img-right{/*padding-right: 36px;*/}
.content-img-section-2 .content-img-left{/*padding-left: 36px;*/}
.content-img-section .section-title h2 span{font-size: 60px;}
.content-img-section .banner-logos{margin-top: 70px;}

.content-img-section .section-title h4{margin-bottom: 15px;}
/*Content Img Section CSS Ends*/

/*Post Section CSS Starts*/
.post-section{padding-top: 100px;padding-bottom: 100px;}
.post-inner{}
.post-box-outer{margin-top: 100px;}
.post-box{padding: 8px;}
.post-box-inner,.post-box-img img,.post-box-content{min-height: 610px;}
.post-box-title p,.post-box-desc p{color: #ffffff;}
.post-box-inner{border-radius: 16px;overflow: hidden;position: relative;}
.post-box-inner:before{content: '';width: 100%;height: 100%;position: absolute;top: 0;left: 0;background: linear-gradient(180.01deg, rgba(0, 0, 0, 0) 50%, #000000 75.81%);z-index: 9;}
.post-box-img{position: absolute;}
.post-box-img img{width: 100%;object-fit: cover;}
.post-box-content{position:relative;display:flex;flex-direction:row;flex-wrap:wrap;align-content:flex-end;z-index:9;padding:20px 25px}
.post-box-tag{width: 100%;}
.post-box-tag ul{display: flex;flex-wrap: wrap;flex-direction: row;margin: 0px;padding: 0px;}
.post-box-tag ul li{list-style: none;margin-right: 8px;}
.post-box-tag ul li a{min-width: 135px;padding: 8px 0px;text-align: center;color: #ffffff;font-size: 16px;line-height: 19px;display: block;border-radius: 38.6181px;background: linear-gradient(92.31deg, #EA1C2C -1.24%, #FFC56F 133.49%);}
.post-box-tag ul li.lesiones a{background: linear-gradient(92.31deg, #520EA8 -1.24%, #DF85FF 133.49%);}
.post-box-tag ul li.salud a{background: linear-gradient(92.31deg, #0561B6 -1.24%, #6FE5FF 133.49%);}
.post-box-tag ul li:last-child{margin-right: 0px;}
.post-box-title{width: 100%;margin-top: 10px;}
.post-box-title p{}
.post-box-title p a{color: inherit;}
.post-box-desc{width: 100%;}
.post-box-desc p{font-size: 16px;line-height: 24px;color: rgba(255,255,255,0.8);}
/*Post Section CSS Ends*/

/*Program Section CSS Starts*/
.program-section{padding-bottom: 70px;}
.program-inner{}
.program-left{padding-right: 62px;}
.program-right{}
.program-section .section-desc{margin-top: 36px;}
.program-btn-outer{margin-top: 50px;}
.program-img{}
.program-img img{}
/*Program Section CSS Ends*/

/*Faq Section CSS Starts*/
.faq-section{padding-top: 110px;padding-bottom: 200px;background-color: #1D1D1B;position: relative;z-index: 9;}
.faq-inner{}
.faq-section .section-title h2{font-size: 60px;}
.faq-outer{margin-top: 90px;}
.faq-section .card{background-color:transparent;background-clip:border-box;border:0;border-bottom:1px solid #fff;border-radius:0;margin-bottom:80px}
.faq-section .card-header .btn-link,.faq-section .card-body p{color: #ffffff;}
.faq-section .card-header{padding:0;margin-bottom:0;background-color:transparent;border-bottom:0}
.faq-section .card-header .btn-link{display:block!important;width:100%!important;text-align:left!important;padding:0!important;padding-bottom:20px!important;font-size:24px!important;line-height:48px!important;box-shadow:unset!important;background-image:url(../images/icon-arrow-down.svg);background-position:95% 22px;padding-right:8%!important}
.faq-section .card-body{padding: 0px;padding-bottom: 12px;}
.faq-section .card-body p{font-size: 16px;line-height: 24px;color: rgba(255,255,255,0.8);}
.faq-section-2{padding-bottom: 50px;background-color: transparent !important;}
.faq-section-2 .card-header .btn-link,.faq-section-2 .card-body p{color: #111111;}
.faq-section-2 .card{border-bottom-color: #000000;}
.faq-section-2 .card-header .btn-link{background-image:url(../images/icon-arrow-down-black.svg)}
/*Faq Section CSS Ends*/

/*Mockup Section CSS Starts*/
.mockup-section{padding-top: 70px;background: linear-gradient(136.05deg, #000000 -71.2%, #C9002C 62.6%);}
.mockup-inner{}
.mockup-section .section-title h2{font-size:80px;line-height:100px}
.mockup-btn-outer{margin-top: 25px;}
.mockup-btn-outer .btn-link{}
.mockup-box-outer{margin-top: 80px;}
.mockup-box-outer img{width: 100%;}
.mockup-box-left{}
.mockup-box-right{}
.mockup-box-left img{transform: translateX(15px);}
.mockup-box-right img{margin-left: -15px;}
/*Mockup Section CSS Ends*/

/*Digital Section CSS Starts*/
.digital-section{padding-bottom: 80px;background-image: url("../images/BGGradient.png");background-repeat: no-repeat;background-position: top right;}
.digital-inner{}
.digital-section .section-title{}
.digital-section .section-title h2{line-height: 120px;}
.digital-section .section-desc{max-width: 995px;display: block;margin: auto;margin-top: 36px;}
/* .digital-form{max-width: 452px;display: block;margin: auto;margin-top: 36px;} */
.digital-form{max-width: 100%;display: block;margin: auto;margin-top: 50px;text-align: center;}
.digital-form-inner,.digital-form-inner .form-control{border-radius: 12px;}
.digital-form-inner{display: flex;flex-wrap:wrap;flex-direction: row;border: 1px solid #162836;background-color: #ffffff;}
.digital-form-inner .form-control,.digital-form-inner .btn{font-size: 20px;}
.digital-form-inner .form-control{width: 61%;border: 0px;}
.digital-form-inner .btn{width: 39%;padding: 11px 0px;font-size: 20px;border-radius: 11px;}
.digital-form-info{margin-top: 36px;}
.digital-form-info p{}
.digital-form-img{margin-top: 50px;}
.digital-form-img img{}
/*Digital Section CSS Ends*/

/*Market Section CSS Starts*/
.market-section{padding-top: 100px;padding-bottom: 80px;}
.market-inner{}
.market-section .section-title h2{margin-top: 20px;margin-bottom: 30px;}
.market-img{margin-top: 50px;text-align: center;}
.market-img img{}
/*Market Section CSS Ends*/

/*PBox Section CSS Starts*/
.pbox-section{}
.pbox-inner{}
.pbox-b{padding: 30px;}
.pbox-b-inner{}
.pbox-title{}
.pbox-title h2{font-size: 48px;line-height: 60px;}
.pbox-desc{}
.pbox-desc p{}
/*PBox Section CSS Ends*/

/*Grid Section CSS Starts*/
.grid-section{padding-top: 150px;padding-bottom: 100px;}
.grid-inner{}
.grid-section .section-desc{margin-top: 50px;}
.grid-box-outer{margin-top: 100px;}
.grid-box{padding: 8px;}
.grid-box-inner,.grid-box-img img,.grid-box-content{min-height: 610px;}
.grid-box-title h4,.grid-box-desc p{color: #ffffff;}
.grid-box-inner{border-radius: 16px;overflow: hidden;position: relative;}
.grid-box-inner:before{content: '';width: 100%;height: 100%;position: absolute;top: 0;left: 0;/*background: linear-gradient(180.01deg, rgba(0, 0, 0, 0) 50%, #000000 75.81%);*/z-index: 9;}
.grid-box-img{position: absolute;}
.grid-box-img img{width: 100%;object-fit: cover;}
.grid-box-content{position:relative;display:flex;flex-direction:row;flex-wrap:wrap;align-content:flex-end;z-index:9;padding:20px 25px}
.grid-box-title{width: 100%;margin-top: 10px;}
.grid-box-title h4{font-size: 26px;line-height: 38px;}
.grid-box-title h4 b{border-left: 5px solid #EA1C2C;padding-left: 5px;}
.grid-box-title h4 span{}
.grid-box-title h4 a{color: inherit;}
.grid-box-desc{width: 100%;margin-top: 10px;}
.grid-box-desc p{font-size: 16px;line-height: 24px;color: #DFDFDF;}
.btn-grid-outer{margin-top: 80px;}
.btn-grid-outer .btn{}
/*Grid Section CSS Ends*/


/*Price Section CSS Starts*/
.price-section{padding-top: 120px;padding-bottom: 150px;background-color: #111111;}
.price-inner{}
.price-section .section-desc p,.price-info-top p,.price-info p{color: rgba(255, 255, 255, 0.8);}
.price-info-top p,.price-info p{font-size: 16px;line-height: 24px;}
.price-section .section-title{}
.price-section .section-title h2{}
.price-section .section-desc{margin-top: 25px;}
.price-section .section-title p{}
.price-info-top{margin-top: 10px;}
.price-info-top p{}
.price-info-top p a{color: #ffffff;text-decoration: underline;text-underline-position: under;}
.btn-price-outer{margin-top: 100px;}
.price-info{margin-top: 25px;}
.price-info p{}
/*Price Section CSS Ends*/

/*Scroll Section CSS Starts*/
.left-scroll-outer{margin-top: 60px;}
.right-scroll-outer{}
.shadow-seciton{position: relative;}
.shadow-seciton:before,.shadow-seciton:after{content: '';position: absolute;left: 0;width: 100%;height: 0;z-index: 9;}
.shadow-seciton:before{top: 0;box-shadow: 0 0px 75px 90px #fff;}
.shadow-seciton:after{bottom: 0;box-shadow: 0 3px 52px 95px #fff;}
/* .scroll-box-outer:before,.scroll-box-outer:after{content: '';position: absolute;left: 0;width: 100%;height: 0;z-index: 9;}
.scroll-box-outer:before{top: 0;box-shadow: 0 0px 75px 90px #fff;}
.scroll-box-outer:after{bottom: 0;box-shadow: 0 3px 52px 95px #fff;} */
.scroll-box-outer{position: relative;}
.scroll-box{padding: 7px 7px;position: relative;}
.scroll-box-inner{position: relative;}
.scroll-box-inner:before{content: '';width: 100%;height: 100%;position: absolute;top: 0;left: 0;/*background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.6%, #000000 75.09%);*/}
.scroll-box-inner,.scroll-box-img img{height: 210px;border-radius: 10px;overflow: hidden;}
.scroll-box-img{}
.scroll-box-img img{width: 100%;object-fit: cover;}
.scroll-box-content{position: absolute;left: 0px;bottom: 10px;width: 100%;padding: 10px 20px;}
.scroll-box-content h5,.scroll-box-content p{margin: 0px;color: #ffffff;}
.scroll-box-content h5{font-size: 22px;line-height: 34px;}
.scroll-box-content p{font-size: 14px;line-height: 21px;text-align: center;margin-top: 20px;}

/*Scroll Section CSS Ends*/

/*Footer CSS Starts*/
.footer-section{}
.footer-inner{}
.footer-box p,.footer-box a,.footer-copy p{font-size: 20px;line-height: 30px;color: #4E4E4E;text-decoration: none;}
.footer-link{color: #4E4E4E;text-decoration: none;}
.footer-box-section{padding-top: 50px;padding-bottom: 10px;}
.footer-box-outer{}
.footer-box{}
.footer-box-inner{}
.footer-box p{}
.footer-logo{}
.footer-logo img{}
.footer-box h3
{font-size: 20px;margin-bottom: 15px;}
.footer-menu{}
.footer-menu ul{margin: 0px;padding: 0px;}
.footer-menu ul li{list-style: none;margin-bottom: 0px;}
.footer-menu ul li a{}
.footer-menu ul li a:hover{color: #007BB7;}
.footer-social{}
.footer-social ul{margin: 0px;padding: 0px;display: flex;flex-wrap: wrap;flex-direction: row;}
.footer-social ul li{list-style: none;margin-right: 8px;}
.footer-social ul li a{}
.footer-form{}
.footer-form-inner,.footer-form .form-control{border-radius: 12px;}
.footer-form-inner{display: flex;flex-wrap: wrap;flex-direction: row;border: 1px solid #0C0C0C;}
.footer-form .form-control{width: 80%;font-size: 20px;border: 0px;}
.footer-form .btn{width: 20%;padding: 11px 0px;}
.footer-copy{margin-top: 100px;}
.footer-copy p{margin: 0px;}
/*Footer CSS Ends*/



/* Popup Section end */

.hover-cursor:hover{cursor:pointer}
.my-form{width:100%!important;height:500px}
.price-img{width: 80%;margin-left: auto;margin-right: auto;margin-bottom: 10px;margin-top: 90px;}
.slider-left-text{font-size: 16px;color:#FFF;text-align: left;margin-bottom: 5px;font-family: Poppins-Light;}
.slider-right-value{font-family: Poppins-Regular;font-size: 200px;font-weight: 500;color:#FFF;-webkit-font-smoothing:auto;-webkit-text-size-adjust: 100%;transition: all .3s ease-in;margin-top: 130px;}
.slider-left-top{font-size: 24px;font-weight: 500;font-family: Poppins-Light;color: #FFFFFF;}
.switch-label{margin-bottom: 120px;}
.react-switch-bg div:nth-child(2) {width: 95px !important;}
.left-scroll-outer .scroll-inner img{width: 100%;}
.left-scroll-outer{background: linear-gradient(179.92deg, #FFFFFF 8.19%, rgba(196, 196, 196, 0) 25.86%);}
.scroll-inner{background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.6%, #000000 75.09%), url('../images/MacBookPro17.png');background-size: 100%;background-repeat: no-repeat;}
.scroll-inner{height: 260px;}
.scroll-inner h5{color:#FFF;padding: 180px 20px 0px 20px;font-size:22px;font-weight: 400;font-family: Poppins-Regular;}
.scroll-inner p{color:#FFF;font-size: 14px;text-align: center;margin-bottom: 0px;}
.custom-height{height: 900px;}
.left-scroll-outer{height: 901px;overflow-y: scroll;pointer-events: none;}
.right-scroll-outer{height: 901px;overflow-y: scroll;pointer-events: none;}
.left-scroll-outer::-webkit-scrollbar {display: none;}
.left-scroll-outer {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */}
.right-scroll-outer::-webkit-scrollbar {display: none;}
.right-scroll-outer {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */}
  
  /* Hide scrollbar for IE, Edge and Firefox */

  .lang-btn{border:none;background:none;}
  .lang-btn:hover:focus:active{border:none;background:none;}
  .ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.image, .ui.dropdown>.text>img{   max-height: 1em;vertical-align: middle;}
  
/*Media Query CSS Starts*/

@media (min-width: 1441px) and (max-width: 2560px)  {
    #tv{
        width: 86%;
    }
}

@media screen and (max-width: 1440px)
{
    .content-img-section-02{background-position: 95% 0;}
    .content-img-section-001{background-position: 0 50%;}
    header ul.navbar-nav{padding-left:0;margin-left:auto}
    .section-title h2{font-size:59px;line-height:80px}
    .section-title-large h2{font-size:80px;line-height:100px}
    .digital-section .section-title h2{font-size:100px}
    .mockup-section .section-title h2{font-size:70px;line-height:90px}
    .banner-title h1{font-size:88px;line-height:116px}
    .content-img-section .section-title h2 span{font-size:57px}
    .pbox-b{padding:25px}
    .pbox-title h2{font-size:42px;line-height:50px}
    #tv{width:100%;height: 680px;}
}
@media screen and (max-width: 1399px)  {
    #tv{width:100%;height:586px;border-radius:25px}
}

@media screen and (max-width: 1200px)
{
    body{font-size: 20px;}
    .banner-title h1{font-size:72px;line-height:82px}
    .section-title h2,.content-img-section .section-title h2 span{font-size: 48px;line-height: 60px;}
    .section-title-large h2{font-size:65px;line-height:80px}
    .banner-img img{max-width:720px}
    .content-img-section-1{background-size:480px;background-position:0 150px}
    .content-img-section-2{background-size:470px}
    .content-img-section-3{background-size:470px}
    .digital-section .section-title h2{font-size:80px;line-height:100px}
    .content-img-section-01{background-size: 500px;background-position: 0 18%;padding-bottom: 70px;}
    .content-img-section-02{background-size: 520px;background-position: 100% 19%;padding-top: 80px;padding-bottom: 80px;}
    .content-img-section-001{background-position: 0 50%;background-size: 570px;padding-top: 160px;padding-bottom: 120px;}
    .market-section{padding-top: 50px;padding-bottom: 50px;}
    .pbox-title h2{font-size: 40px;line-height: 44px;}
    .pbox-desc{margin-top: 12px;}
    .faq-section .card-header .btn-link{font-size: 20px!important;}
    .faq-section .card{margin-bottom: 50px;}
    .scroll-box-content h5{font-size: 15px;line-height: 20px;}
    .scroll-box-content p{font-size: 13px;}
    .footer-box h3{font-size:18px}
    .footer-box a,.footer-box p,.footer-copy p{font-size:18px;line-height:26px}
    #tv{width:100%;height:493px;border-radius:25px}
}

@media screen and (max-width: 990px)
{
    header{padding:15px 0;}
    .navbar-collapse{position:fixed;width:100%;height:100vh!important;top:0;left:100%;padding:115px 15px 15px 15px;z-index:99999;background-color:#013f87;transition:all .3s ease-in}
    .navbar-collapse.show{left:0}
    .navbar-toggler{z-index:99999999999;border:0;outline:unset!important;width:26px;height:26px;position:relative}
    .navbar-toggler-icon{display:none}
    .menu-icon{position:absolute;top:0;left:0}
    .navbar-toggler[aria-expanded=false] .toggle-icon{display:block}
    .navbar-toggler[aria-expanded=false] .close-icon{display:none}
    .navbar-toggler[aria-expanded=true] .close-icon{display:block}
    .navbar-toggler[aria-expanded=true] .toggle-icon{display:none}
    header ul.navbar-nav li{margin:0;margin-bottom: 36px;}
    header ul.navbar-nav li a{font-size:44px;line-height:53px;color:#fff!important}
    .banner-left{padding-right:0}
    .banner-img img{max-width:100%;margin-left:0;margin-top:0}
    .content-img-section-1{background-size:100%;background-position:0 60px;padding-top:800px}
    .content-img-section-2{background-size:100%;background-position:100% 60px;padding-top:850px}
    .content-img-section-3{background-size:100%;background-position:0 60px;padding-top:1150px}
    .content-img-section-2 .content-img-data{margin-right:0}
    .post-box-content,.post-box-img img,.post-box-inner{min-height:500px}
    .program-left{padding-right:0}
    .digital-section .section-title h2{font-size:80px;line-height:100px}
    .content-img-section-01{background-size:700px;padding-top:950px}
    .content-img-section-02{background-size:700px;background-position: 54% 0;padding-top:690px}
    .content-img-section-001{background-position: 50% 0%;background-size: 750px;padding-top: 920px;padding-bottom: 60px;}
    .market-section{padding-top:50px;padding-bottom:50px}
    .grid-section{padding-top:50px}
    .grid-box-content,.grid-box-img img,.grid-box-inner{min-height:500px}
    .content-img-section .section-title h2 span{font-size:inherit}
    #tv{
        height: 367px;
    }
    .btn-pink {
        padding: 6px 28px !important;
    }}

@media screen and (max-width: 767px)
{
    .btn{font-size: 20px;}
    header{padding-top:16px;padding-bottom:20px}
    .navbar-brand img{/*height:50px*/}
    header ul.navbar-nav li.btn{margin-left:0}
    .popup-inner{width:100%;padding:20px 50px 80px 30px}
    .popup-close{right:30px}
    .popup-content{margin-top:18px}
    .popup-title h2{font-size:34px;line-height:50px}
    .popup-desc p{font-size:15px;line-height:24px}
    .cookies-popup-btn-outer{text-align:left;margin-top:15px}
    .faq-section .section-title h2,.section-title h2{font-size: 36px;line-height: 46px;}
    .content-img-data .section-desc{margin-top: 20px;}
    .banner-desc p,.section-desc p{font-size:18px;line-height:30px}
    .mockup-section .section-title h2{font-size:39px;line-height:50px}
    .faq-section .section-title h2{font-size:50px}
    .banner-title h1{font-size:65px;line-height:76px}
    .faq-section .card-header .btn-link{font-size:20px!important;line-height:34px!important}
    .banner-logos ul li a img{height:50px}
    .content-img-section {padding-bottom: 50px;background-size: 460px;background-position: 28% 0;padding-top: 570px;}
    .content-img-section-2 .content-img-pic img{margin-top:0}
    .content-img-section-1{padding-top:430px}
    .content-img-section-2{padding-top:460px}
    .content-img-section-3{padding-top:600px}
    .post-section{padding-top:50px;padding-bottom:70px}
    .post-box-content,.post-box-img img,.post-box-inner{min-height:450px}
    .faq-section{padding-top:50px;padding-bottom:50px}
    .digital-section{padding-bottom: 20px;background-size:600px}
    .digital-section .section-title h2{font-size:42px;line-height:49px}
    .content-img-section-01{background-size: 320px;padding-top: 430px;background-position: 0 4%;margin-bottom: 50px;}
    .section-title h4{font-size: 19px;line-height: 26px;}
    .content-img-section-02{background-size: 400px;padding-top: 420px;background-position: 50% 0;margin-top:10px;margin-bottom: 50px;}
    .pbox-b{padding:15px;margin-bottom: 30px;}
    .market-section{padding-top: 90px;}
    .mar-b-50{margin-bottom: 50px;}
    .pbox-title h2{font-size:36px;line-height:40px}
    .grid-box-content,.grid-box-img img,.grid-box-inner{min-height:450px}
    .mockup-box-outer{margin-top:70px}
    .program-left{position: relative;z-index: 99;}
    .scroll-box-content h5{font-size: 14px;line-height: 20px;}
    .scroll-box-content p{font-size: 11px;}
    .footer-box{margin-bottom:30px}
    .footer-copy{margin-top:36px}
    .price-img{margin-top: 30px;margin-bottom: 70px;}
    .slider-right-value{font-size: 48px;line-height: 90px;margin-top: 70px;margin-bottom: 0px;}
    .switch-label{margin-top: 50px;margin-bottom: 70px;}
    .shadow-seciton:before {
        top: 0;
        box-shadow: 80px 11px 50px 130px #fff;
    }
    /* .lang-btn{color:#FFF;} */
    /* .divider{color:#FFF;} */
    /* .ui.inline.dropdown .dropdown.icon{color: #FFF;} */
    .banner-logos ul{flex-wrap: unset;}
    .btn-gradient{width: 100%;padding: 15px 55px;}
    .monthly-selected{padding-top:0px}
    .monthly-unselected{font-size:15px;padding-left:20px;padding-top:15px;color:#FFF}
    .yearly-unselected{font-size:15px;padding-top:16px;color:#FFF}
    #tv{width:100%;height: 217px;}
    .footer-box a, .footer-box p, .footer-copy p{font-size: 16px;line-height: 24px;}
}
/*Media Query CSS Ends*/
